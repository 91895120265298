<template>
  <el-main>
    <div class="group-info">
      <div>
        <span>群名称：</span>
        {{ info.name || '未设置' }}
      </div>
      <div>
        <span>群主：</span>
        {{ info.owner }}
      </div>
      <div v-show="info.create_time">
        <span>创建时间：</span>
        {{ getDateformat(info.create_time) }}
      </div>
    </div>
    <div class="search">
      <el-input v-model="name" size="small" placeholder="请输入群成员名称"></el-input>
      <el-button type="primary" size="small" @click="search">点击查询</el-button>
    </div>
    <el-table :data="info.member_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="群成员"></el-table-column>
      <el-table-column prop="join_scene" label="进群方式">
        <template v-slot="{ row }">
          {{ row.join_scene == 1 ? '由群成员邀请入群（直接邀请入群）' : row.join_scene == 2 ? '由群成员邀请入群（通过邀请链接入群）' : '通过扫描群二维码入群 ' }}
        </template>
      </el-table-column>
      <el-table-column prop="count" label="进群时间">
        <template v-slot="{ row }">
          {{ getDateformat(row.join_time) }}
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';
export default {
  data() {
    return {
      name: '',
      info: {},
      member_list: [],
    };
  },
  created() {
    this.$axios
      .post(this.$api.user.wxwork.groupchatDetail, {
        chat_id: this.$route.query.chat_id,
      })
      .then(res => {
        if (res.code == 0) {
          this.info = res.result.group_chat;
          this.member_list = _.cloneDeep(this.info.member_list);
        }
      });
  },
  methods: {
    search() {
      let arr = [];
      if (this.name) {
        this.member_list.map(item => {
          if (item.name.indexOf(this.name) >= 0) arr.push(item);
        });
        this.info.member_list = arr;
      } else {
        this.info.member_list = this.member_list;
      }
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .group-info {
    display: flex;
    margin-bottom: 20px;
    div {
      span {
        color: #606266;
      }
      margin-right: 30px;
    }
  }
  .search {
    display: flex;
    margin-bottom: 20px;
    .el-input {
      width: 320px;
      margin-right: 20px;
    }
  }
}
</style>